<template>
  <v-col cols="12" class="px-8" v-if="permisoVista('matrix','r')">
    <v-row>
      <v-col cols="4" md="4" sm="4">
        <datePicker
          v-model="fecha"
          label="Fecha (Obligatorio)"
          format="YYYY-MM-DD"
          clearable
          maxToday
          @input="ejecutar"
        ></datePicker>
      </v-col>
      <v-col cols="4" md="4" sm="4">
        <v-autocomplete
          v-model="idArea"
          label="Área (Obligatorio)"
          :items="areas"
          item-value="idArea"
          item-text="nombre"
          clearable
          @change="ejecutar"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4" md="4" sm="4">
        <v-autocomplete
          v-model="idMaquinaIns"
          label="Máquina (Opcional)"
          :items="maquinasFiltradas"
          item-value="idMaquinaIns"
          :item-text="nombreMaquina"
          clearable
          @change="ejecutar"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row v-if="realizarPeticion" class="px-0 ma-0">
      <v-col cols="12" class="ma-0 text-right text-subtitle-2">
        Proxima Actualización en {{tiempo}}
      </v-col>
    </v-row> 
    <v-progress-linear
      v-if="loading"
			color="primary"
			indeterminate
		></v-progress-linear>
    <v-row v-if="!loading">
      <v-col cols="2" md="2" :sm="2">
        <v-card class="fill-height d-flex">
          <v-card-text class="text-center align-self-center">
            <div class="subtitle-1 font-weight-bold">
              # Piezas
            </div>
            <ICountUp
              :delay="1000"
              :endVal="numPiezas"
              class="subtitle-1 font-weight-medium"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="2" md="2" :sm="2">
        <v-card class="fill-height d-flex">
          <v-card-text class="text-center align-self-center">
            <div class="subtitle-1 font-weight-bold">
              # Piezas OK
            </div>
            <ICountUp
              :delay="1000"
              :endVal="numPiezasOk"
              class="subtitle-1 font-weight-medium"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="2" md="2" :sm="2">
        <v-card class="fill-height d-flex">
          <v-card-text class="text-center align-self-center">
            <div class="subtitle-1 font-weight-bold">
              # Piezas No OK
            </div>
            <ICountUp
              :delay="1000"
              :endVal="numPiezasNoOk"
              class="subtitle-1 font-weight-medium"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="2" md="2" :sm="2">
        <v-card class="fill-height d-flex">
          <v-card-text class="text-center align-self-center">
            <div class="subtitle-1 font-weight-bold">
              % Eficiencia
            </div>
            <ICountUp
              :delay="1000"
              :endVal="efectividad"
              class="subtitle-1 font-weight-medium"
              :options="{
                decimalPlaces: 2,
                separator: ',',
                decimal: '.',
                suffix: '%'
              }"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="2" md="2" :sm="2">
        <v-card class="fill-height d-flex">
          <v-card-text class="text-center align-self-center">
            <div class="subtitle-1 font-weight-bold">
              % Calidad
            </div>
            <ICountUp
              :delay="1000"
              :endVal="calidad"
              class="subtitle-1 font-weight-medium"
              :options="{
                decimalPlaces: 2,
                separator: ',',
                decimal: '.',
                suffix: '%'
              }"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="2" md="2" :sm="2">
        <v-card class="fill-height d-flex">
          <v-card-text class="text-center align-self-center">
            <div class="subtitle-1 font-weight-bold">
              Tiempo Muerto
            </div>
            <ICountUp
              :delay="1000"
              :endVal="tiempoMuerto"
              class="subtitle-1 font-weight-medium"
              :options="{
                decimalPlaces: 2,
                separator: ',',
                decimal: '.',
                suffix: ' hrs'
              }"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <br>
    <v-data-table
      v-if="!loading"
      :items-per-page="-1"
      :items="datos"
      :headers="headers"
      hide-default-footer
    >
      <template v-slot:item="{ item }">
        <tr v-for="(op,idx) in item.operacionRealReportes" :key="'op'+op.idOperacionReal">
          <td class="text-center" v-if="idx == 0" :rowspan="item.operacionRealReportes.length+1">{{item.inicio}}<br>-<br>{{item.fin}}</td>
          <td class="text-center">{{op.articulo}}</td>
          <td class="text-center">{{(op.cantidadProducida - op.cantidadReportada).toFixed(2)}} {{op.unidad}} <v-icon>{{op.sensor ? 'mdi-thermometer':'mdi-hand-right'}}</v-icon></td>
          <td class="text-center">{{op.cantidadReportada}} {{op.unidad}}</td>
          <td></td>
          <td></td>
          <td class="text-center"> {{secureDouble((op.cantidadProducida - op.cantidadReportada) /op.cantidadProducida)}}  </td>
          <td></td>
          <td>
            <ul>
              <li v-for="(e,idx) in op.motivoParos" :key="`e-${idx}`">{{e}}</li>
            </ul>
          </td>
          <td class="text-center">{{op.scrap}} {{op.unidad}}</td>
          <td class="text-center">{{op.retrabajo}} {{op.unidad}}</td>
        </tr>
        <tr>
          <td class="text-center" v-if="item.operacionRealReportes.length == 0" >{{item.inicio}}<br>-<br>{{item.fin}}</td>
          <td class="text-center font-weight-bold">TOTAL</td>
          <td class="text-center font-weight-bold">{{(item.cantidadProducida - item.cantidadReportada).toFixed(2)}}</td>
          <td class="text-center font-weight-bold">{{item.cantidadReportada}}</td>
          <td class="text-center font-weight-bold">{{item.eficiencia.toFixed(2)}}%</td>
          <td class="text-center font-weight-bold">{{item.disponibilidad.toFixed(2)}}%</td>
          <td class="text-center font-weight-bold">{{item.calidad.toFixed(2)}}%</td>
          <td  class="text-center font-weight-bold">{{item.tiempoMuerto}} min</td>
          <td><ul>
              <li v-for="(e,idx) in item.motivoParos" :key="`e-${idx}`">{{e}}</li>
            </ul></td>
          <td class="text-center font-weight-bold">{{item.scrap}}</td>
          <td class="text-center font-weight-bold">{{item.retrabajo}}</td>
        </tr>
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import axios from "axios";
import ICountUp from 'vue-countup-v2';

export default {
  components: {
    ICountUp
  },
  data: () => ({
    areas:[],
    idArea: -1,
    fecha: null,
    datos:[],
    headers:[
      {
        text: "Horario",
        align: "center",
        sortable: false,
        value: "horario",
        width: "10%",
      },
      {
        text: "Artículo",
        align: "center",
        sortable: false,
        value: "articulo",
        width: "10%",
      },
      {
        text: "Piezas OK",
        align: "center",
        sortable: false,
        value: "cantidadProducida",
        width: "10%",
      },
      {
        text: "Piezas No OK",
        align: "center",
        sortable: false,
        value: "cantidadReportada",
        width: "10%",
      },
      {
        text: "Disponibilidad",
        align: "center",
        sortable: false,
        value: "eficiencia",
        width: "10%",
      },{
        text: "Eficiencia",
        align: "center",
        sortable: false,
        value: "disponibilidad",
        width: "10%",
      },
      {
        text: "Indice de Calidad",
        align: "center",
        sortable: false,
        value: "calidad",
        width: "10%",
      },
      {
        text: "Tiempo Muerto",
        align: "center",
        sortable: false,
        value: "calidad",
        width: "10%",
      },
      {
        text: "Código de Falla",
        align: "center",
        sortable: false,
        value: "calidad",
        width: "10%",
      },
      {
        text: "Cantidad Scrap",
        align: "center",
        sortable: false,
        value: "scrap",
        width: "10%",
      },
      {
        text: "Cantidad Retrabajo",
        align: "center",
        sortable: false,
        value: "retrabajo",
        width: "10%",
      }
    ],
    loading: false,
    segundos: 0,
    intervalo: null,
    maquinas:[],
    idMaquinaIns:-1,
    peticiones: 0,
  }),
  computed:{
    maquinasFiltradas(){
      if(this.$utils.isValid(this.idArea) && this.idArea > 1) return this.maquinas.filter(m => m.idArea == this.idArea);
      else return this.maquinas;
    },
    realizarPeticion(){
      return ((this.$utils.isValid(this.idArea) && this.idArea > 1) && (this.$utils.isValid(this.fecha) && this.fecha != null));
    },
    tiempo() {
      return this.$utils.strings.toTime(this.segundos);
    },
    numPiezas(){
      return this.datos.reduce((a, c) => a + c.cantidadProducida, 0)
    },
    numPiezasOk(){
      return this.datos.reduce((a, c) => a + (c.cantidadProducida - c.cantidadReportada), 0)
    },
    numPiezasNoOk(){
      return this.datos.reduce((a, c) => a + c.cantidadReportada, 0)
    },
    efectividad(){
      return (this.datos.reduce((a, c) => a + c.tiempoEfectivo, 0) / 1440);
    },
    calidad(){
      return ((this.datos.reduce((a, c) => a + c.cantidadProducida, 0) - this.datos.reduce((a, c) => a + c.cantidadReportada, 0)) / this.datos.reduce((a, c) => a + c.cantidadProducida, 0))*100.0;
    },
    tiempoMuerto(){
      return this.datos.reduce((a, c) => a + c.tiempoMuerto, 0) / 60;
    }
  },
  methods:{
    nombreMaquina: maquina => `${maquina.marca} ${maquina.modelo} - [${maquina.nombre}]`,
    getAreas(){
      axios
      .get("/Areas")
      .then(response => {
        this.areas = response.data.filter(a => a.idArea > 1);
      })
      .catch(error => {
        console.log(error);
      })
    },
    ejecutar(){
      if(this.realizarPeticion)
      {
        this.getDatos();
      }
      else{
        this.datos = [];
      }
    },
    getDatos(){
      if(!this.realizarPeticion) return;
      this.loading = true;
      this.segundos = 60;
      var url = `/Reportes/ReporteAreaRendimiento?IdArea=${this.idArea}&Fecha=${this.fecha}`;
      if(this.$utils.isValid(this.idMaquinaIns) && this.idMaquinaIns > 0) url += `&IdMaquinaIns=${this.idMaquinaIns}`;

      axios
      .get(url)
      .then(response => {
        this.datos = response.data;
        setTimeout(() => {
          this.loading = false;
        },1000);
      })
      .catch(error => {
        console.log(error);
        this.loading = false;
      })
    },
    getMaquinasInstancias(){
      axios
				.get("/MaquinaInstancias")
				.then((response) => {
					this.maquinas = response.data.filter(a => a.idArea > 1 && a.esMaquina == true);
				})
				.catch((error) => {
					console.log(error);
				});
    },
    secureDouble(value){
      if(isNaN(value)) return "0.00%";
      else return `${(value * 100).toFixed(2)}%`;
    }
  },
  mounted(){
    this.getAreas();
    this.getMaquinasInstancias();
    this.intervalo = setInterval(() => {
      if(this.segundos == 0){
        this.getDatos();
      }
      else{
        this.segundos -= 1;
      }
    },1000);
  },
  destroyed(){
    clearInterval(this.intervalo);
    this.intervalo = null;
  }
  
}
</script>