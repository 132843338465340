<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="6" md="6" sm="6" class="align-self-end">
        <datePicker
          v-model="fecha"
          label="Fecha (Obligatorio)"
          format="YYYY-MM-DD"
          clearable
          maxToday
          @input="getOperadores();getEstadisticas();resetPersonas();"
        ></datePicker>
      </v-col>
      <v-col cols="6" md="6" sm="6" class="align-self-end">
        <v-autocomplete
          v-model="idTurno"
          :items="turnos"
          item-text="nombre"
          item-value="idTurno"
          label="Turno (Opcional)"
          clearable
          hide-details
          @change="getOperadores();getEstadisticas"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="6" sm="6">
        <v-autocomplete
          v-model="idsPersonas"
          label="Operador(es) (Obligatorio)"
          :items="personas"
          item-value="idPersona"
          :item-text="nombrePersona"
          hide-details
          multiple
          clearable
          chips
          deletable-chips
          @change="getEstadisticas"
          :loading="loadingOperador"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="6" sm="6" class="align-self-end">
        <v-autocomplete
          v-model="idArea"
          :items="areas"
          item-text="nombre"
          item-value="idArea"
          label="Área (Opcional)"
          clearable
          hide-details
          @change="getEstadisticas"
        ></v-autocomplete>
      </v-col>
      
      <v-col cols="12" class="pb-0">
        <download-btn
          text
          color="primary"
          className="my-0"
          @click="descargarReporte()"
          label="Descargar Reporte<br/>de Rendimiento por Día"
        />
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="primary"
        ></v-progress-linear>
        <apexchart type="rangeBar" height="500" :options="chartOptions" :series="series"></apexchart>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import moment from "moment";
import axios from "axios";
import datePicker from "@/components/DatePicker";
import { VueDatePicker } from '@mathieustan/vue-datepicker';
import DownloadBtn from "../../../DownloadBtn.vue";

export default {
  components: { DownloadBtn },
  data: () => ({
    series:[],
    /*
    series: [
      // Trabajo
      {
        name: 'Trabajo',
        color: "#008000",
        data: [
          {
            x: 'David Fragoso',
            y: [
              new Date(2020, 11, 18, 9, 0).getTime(),
              new Date(2020, 11, 18, 12, 0).getTime()
            ],
            extraData:{
              clave: "Hola Mundo",
              articuloProducir: "",
              articulo:"",
              area:"",
              cantidad:"",
              cantidadProducir:"",
            }
          },
        ]
      },
    ],
    */
    chartOptions: {
      chart: {
        height: 350,
        type: 'rangeBar',
        locales: [{
          name: "es",
          options: {
            months: ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"],
            shortMonths: ["Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic"],
            days: ["Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado"],
            shortDays: ["Dom","Lun","Mar","Mie","Jue","Vie","Sab"],
            toolbar: {
              exportToSVG: "Descargar SVG",
              exportToPNG: "Descargar PNG",
              exportToCSV: "Descargar CSV",
              menu: "Menu",
              selection: "Seleccionar",
              selectionZoom: "Seleccionar Zoom",
              zoomIn: "Aumentar",
              zoomOut: "Disminuir",
              pan: "Navegación",
              reset: "Reiniciar Zoom"
            }
          }
        }],
        defaultLocale: "es"
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '50%',
          rangeBarGroupRows: true
        }
      },
      fill: {
        type: 'solid'
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false
        }
      },
      legend: {
        position: 'right'
      },
      dataLabels: {
        enabled: true,
        formatter: function(val, opts) {
          var extraData = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].extraData;
          if(extraData.clave != null) return extraData.clave;
          else if(extraData.tipo == 3) return "Tiempo Muerto";
          else return "";
        },
        style: {
          colors: ['#fff', '#fff']
        }
      },
      tooltip: {
        custom: function({series, seriesIndex, dataPointIndex, w}) {
          function zeroPrefix(num, digit) {
            if(digit <= 0) return '0';
            if(num > Math.pow(10,digit-1)) return `${num}`;

            var zero = '';
            for(var i = 0; i < digit; i++) {
              zero += '0';
            }
            return (zero + num).slice(-digit);
          }

          var data = w.config.series[seriesIndex].data[dataPointIndex];

          var horas = Math.trunc(data.extraData.duracion / 60);
          var minutos = data.extraData.duracion - (horas * 60);

          return '<div class="arrow_box">' +
              ((data.extraData.clave != null)?'<span><strong>IdOperacion Real: </strong>'+data.extraData.idOperacionReal+'</span><br>':'')+
            ((data.extraData.clave != null)?'<span><strong>Clave: </strong>'+data.extraData.clave+'</span><br>':'')+
            ((data.extraData.clave == null)?'<span><strong>Tiempo Muerto</strong></span><br>':'')+
            '<span><strong>Inicio: </strong>'+data.extraData.inicio+'</span><br>' +
            '<span><strong>Fin: </strong>'+data.extraData.fin+'</span><br>' +
            '<span><strong>Duración: </strong>'+zeroPrefix(horas,2)+":"+zeroPrefix(minutos,2)+'</span><br>' +
            ((data.extraData.clave != null)?'<span><strong>Área: </strong>'+data.extraData.area+'</span><br>':'')+
            ((data.extraData.clave != null)?'<span><strong>Artículo: </strong>'+data.extraData.articulo+'</span><br>':'')+
            ((data.extraData.clave != null)?'<span><strong>Artículo a Producir: </strong>'+data.extraData.articuloProducir+'</span><br>':'')+
            ((data.extraData.clave != null)?'<span><strong>Cantidad Reportada: </strong>'+data.extraData.cantidad+'</span><br>':'')+
            ((data.extraData.clave != null)?'<span><strong>Cantidad a Producir: </strong>'+data.extraData.cantidadProducir+'</span><br>':'')+
            ((data.extraData.clave != null)?'<span><strong>Cantidad Teórica: </strong>'+data.extraData.cantidadTeorica+'</span><br>':'')+
            ((data.extraData.clave != null)?'<span><strong>Costo Calidad: </strong>'+data.extraData.costoCalidad+'</span><br>':'')+
            ((data.extraData.clave != null)?'<span><strong>Calidad: </strong>'+data.extraData.calidad+'</span><br>':'')+
            ((data.extraData.clave != null)?'<span><strong>Eficiencia: </strong>'+data.extraData.eficiencia+'</span><br>':'')+
            ((data.extraData.clave != null)?'<span><strong>Entrega a Tiempo: </strong>'+data.extraData.entregaTiempo+'</span><br>':'')+      
            '</div>'
        }
      }
    },
    loadingOperador:false,
    personas:[],
    fecha:null,
    idsPersonas:[],
    loading:false,
    areas: [],
    idArea: null,
    turnos: [],
    idTurno: null
  }),
  mounted(){
    this.getAreas();
    this.getTurnos();
  },
  computed:{
    fetchUrl(){
      var url = "/Reportes/ReporteTiempoMuerto?";

      if(this.fecha != null) url += "Fecha="+this.fecha;
      else return null;

      //if (this.idTurno != null && this.idTurno !== 0) {
        //url += `&IdTurno=${this.idTurno}`;
      //}
      if (this.$utils.isValid(this.idsPersonas) && this.idsPersonas.length > 0){
        this.idsPersonas.forEach(x => (url += `&IdOperador=${x || 0}`));
      }
    
      else return null;

      return url;
    },
  },
  methods:{
    nombrePersona: persona => persona.nombre + " " + persona.paterno + " " + persona.materno,
    resetPersonas(){
      this.idsPersonas = [];
    },
    descargarReporte(){
      //ENDPOINT para descargar reporte por Dia
      var url = "/Reportes/ReporteTiempoMuertoXlsx?";

      if(this.fecha != null) url += "Fecha="+this.fecha;
      else return;

      if (this.$utils.isValid(this.idsPersonas) && this.idsPersonas.length > 0){
        this.idsPersonas.forEach(x => (url += `&IdOperador=${x || 0}`));
      }
      else return;

      this.$utils.axios.downloadFile(url,'GET','reporte-rendimiento-'+this.fecha+'.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    },
    getTurnos(){
      axios
        .get('/Turnos')
        .then( response => {
          this.turnos = response.data
        })
        .catch( error => {
          console.log( error );
        })
    },
    getAreas(){
      axios
        .get('/Areas')
        .then( response => {
          this.areas = response.data.filter( area => area.idArea > 1 );
        })
        .catch( error => {
          console.log(error);
        })
    },
    getOperadores (){
      this.idsPersonas = [];
      this.personas = [];
      this.series = [];
      this.loadingOperador = true;
      if (this.fecha == null)return;
      axios
      .get("/Personas/OperadoresRendimientoDia",{
        params:{
          fecha: this.fecha,
          idTurno: this.idTurno
        }
      })
      .then(response =>  {
        this.personas = response.data;
        this.loadingOperador = false;
      })
      .catch(error => {
        this.loadingOperador = false;
        console.log(error);
      });
    },
    getEstadisticas(){
      var url = this.fetchUrl;
      if(url == null){
        this.series = [];
        return;
      };

      this.loading = true;
      axios
      .get(url,{
          params:{
            idArea: this.idArea
        }
      })
      .then(response => {
        this.loading = false;
        this.series = response.data.series
      })
      .catch(error => {
        this.loading = false;
        console.log(error);
      });
    }
  }
};
</script>